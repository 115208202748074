import React from 'react';
import {Row, Col, Card, Button, Container, Image, Accordion, useAccordionButton, Collapse} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/utt_ogo.svg';
import Menu from '../assets/images/menu.svg';

function CustomToggle({ children, eventKey, className = '', setCollapse = () => {} }) {
  const decoratedOnClick = useAccordionButton(eventKey, (res) =>{
    setCollapse()
  });

  return (
    <button
      type="button"
      style={{ backgroundColor: 'white', border: '0' }}
      className={className}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

function Header() {

  const [collapse, setCollapse] = React.useState(false);

  // React.useEffect(() => {
  //   var dropdown = document.querySelector('.dropdown');
  //   document.addEventListener('click', function(event) {
  //     var isClickInside = dropdown.contains(event.target);
  //     if (!isClickInside) {
  //       dropdown.querySelector('.dropdown-content').classList.remove('show');
  //     }
  //   });
  // },[]);

  const handleOpacity = () => {
    setCollapse(true);
    setTimeout(() => {
      setCollapse(false)
    }, 100)
  }
  const onClick = () => {
    window.scrollTo({top: 0});
  }

  return (
    <header style={{zIndex: 4}} className='position-fixed w-100 top-0 header'>
      <Container className='py-2'>
        <Accordion className=''>
          <Row className="d-flex justify-content-between">
            <Col style={{maxWidth: 150}} className=''>
              <CustomToggle>
                <Link onClick={onClick} className="" to={'/'}>
                  <Image style={{height: '100%', width: 140}} src={Logo}/>
                </Link>
              </CustomToggle>
            </Col>
            <Col className='d-flex align-items-center justify-content-end'>
              <div className='d-md-flex d-none align-items-center justify-content-end'>
                  <Link onClick={onClick} className="header-link px-4" to={'/about'}>About</Link>
                  <Link onClick={onClick} className="header-link px-2" to={'/faq'}>FAQ</Link>
                  <Link onClick={onClick} className="header-link px-4" to={'/contact'}>Contact us</Link>
              </div>
              <Button variant='success' style={{marginRight: 10}} className='download-btn'>Download</Button>
              <div className='d-md-none d-flex align-items-center justify-content-end'>
                <CustomToggle setCollapse={handleOpacity} className='btn btn-outline' eventKey={0}>
                  <Image style={{height: 40, width: 40, opacity: collapse ? 0.5 : 1}} src={Menu}/>       
                </CustomToggle>
              </div>
            </Col>
              
            <Accordion.Item className='border-0 d-md-none' eventKey={0}>
              <Col>
                <Accordion.Collapse eventKey={0}>
                  <div className='d-flex justify-content-center'>
                    <CustomToggle className='w-100' eventKey={0}>
                      <div className='d-flex flex-column w-100'>
                        <Link onClick={onClick} className="header-link p-2" to={'/about'}>About</Link>
                        <Link onClick={onClick} className="header-link p-2" to={'/faq'}>FAQ</Link>
                        <Link onClick={onClick} className="header-link p-2" to={'/contact'}>Contact us</Link>
                      </div>
                    </CustomToggle>
                  </div>
                </Accordion.Collapse>
              </Col>
            </Accordion.Item>
          </Row>
        </Accordion>
      </Container>
    </header>
  );
}

export default Header;
