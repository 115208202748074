import { Row, Image, Col, ListGroup } from "react-bootstrap";
import Logo from '../assets/images/utt_ogo.svg';
import Facebook from '../assets/images/facebook.svg';
import Linkedin from '../assets/images/linkedin.svg';
import { Link } from "react-router-dom";

function Footer() {

  const onClick = () => {
    // decoratedOnClick()
    window.scrollTo({top: 0, behavior: 'instant'});
    // navRef.current.toggle()
    // console.log(navRef)
    // var myCollapse = document.getElementsByClassName('collapse')[0];
    // Collapse(myCollapse, {
    //   toggle: true,
    // });
  }

  return (
    <footer style={{fontWeight: 400, marginTop: 'auto'}} className="">
      <Row className="py-3 w-100 m-0 justify-content-center align-items-center">
        <Col xs={12} md={3} className="d-flex flex-row justify-content-center justify-content-md-end align-items-center px-md-5">
          <Link onClick={onClick} className="" to={'/'}>
              <Image style={{height: '100%', width: 140}} src={Logo}/>
          </Link>
        </Col>
        <Col sm={12} className="d-md-none d-md-flex"><hr style={{borderWidth: 0}}/></Col>
        <Col xs={6} md={2} lg={1} style={{borderRight: '1px solid #EAEAEA', borderLeft: '1px solid #EAEAEA', minWidth: 180}} className="d-flex flex-row justify-content-center align-items-center">
          <div>
            <ListGroup.Item>  
                <Link onClick={onClick} className="footer-link px-4" to={'/about'}>About</Link>
            </ListGroup.Item>
            <ListGroup.Item>  
                <Link onClick={onClick} className="footer-link px-4" to={'/faq'}>FAQ</Link>
            </ListGroup.Item>
            <ListGroup.Item>  
                <Link onClick={onClick} className="footer-link px-4" to={'/contact'}>Contact Us</Link>
            </ListGroup.Item>
          </div>
        </Col>
        <Col xs={6} md={3} className="d-flex flex-column justify-content-center align-items-md-start align-items-center">
          <div className="mx-md-3">
            <ListGroup.Item>
              <Link onClick={onClick} className="footer-link" to={'/terms-of-uses'}>
                Terms of use
              </Link>
            </ListGroup.Item>
            <ListGroup.Item>
              <Link onClick={onClick} className="footer-link" to={'/privacy'}>
                Privacy Policy
              </Link>
            </ListGroup.Item>
            <div className="d-flex flex-row align-items-center">
              <ListGroup.Item>Follow us:</ListGroup.Item>
              <div className="mx-2">
                <a href="https://www.facebook.com/UploadTheTruth/" target="_blank"><Image src={Facebook}/></a>
              </div>
              <a href="https://www.linkedin.com/company/uploadthetruth" target="_blank"><Image src={Linkedin}/></a>
            </div>
          </div>
        </Col>
        <Col md={12} className="px-0">
          <hr style={{ backgroundColor: '#EAEAEA'}} className="mt-3"/>
        </Col>
        <Col md={12} className="d-flex justify-content-center align-items-center">
          <span style={{fontWeight: 300, fontSize: 14}}>Copyright © 2024 UploadTheTruth. All rights reserved.</span>
        </Col>
      </Row>
    </footer>
  );
}

export default Footer;
