import React from 'react'
import { Row, Col, Container, Image } from 'react-bootstrap'
// import Mobile1 from '../assets/images/mobile_1.svg'
import Mobile1 from '../assets/images/image-4.png'

function About() {
  return (
    <Container className='p-4 mt-4'>
      <Row>
        <Col
          className='d-flex flex-column justify-content-center align-items-center'
          lg={4}
        >
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <Image style={{ height: 606 }} src={Mobile1} />
            <div
              style={{
                height: 78,
                width: 350,
                zIndex: -1,
                marginTop: -50,
                backgroundColor: '#00A338',
                borderRadius: '50%',
              }}
            />
          </div>
        </Col>
        <Col
          xs={{ order: 'first' }}
          lg={{ order: 'last' }}
          className='mb-5 my-md-0'
        >
          <h1 className='mb-4'>About</h1>
          <p style={{ textAlign: 'justify' }}>
            Welcome to the groundbreaking world of UploadTheTruth, where our goal goes beyond the normal and takes you to a realm of honesty, 
            openness, and worldwide bonding. In a modern era filled with an overwhelming amount of information, false information, and various 
            stories, UploadTheTruth shines as a guiding light, committed to uncovering the different aspects of reality and enabling people to express 
            their personal truths through engaging brief clips.
            <br />
            <br />
            UploadTheTruth strongly believes that truth is not just something to be bought or sold; it is a powerful influence 
            that shapes how we see things, expands our understanding, and brings people together. We welcome you to be a carrier of truth, 
            a teller of genuineness, and a maker of stories that connect with the common encounters of our worldwide society.
            <br />
            <br />
            Our platform is not only a place for sharing information but also a lively space where people from various backgrounds 
            can freely express themselves and display their abilities. If you are a journalist who discovers undisclosed facts, a 
            storyteller who shares impactful stories, or an artist who creates visually impressive narratives, UploadTheTruth gives 
            you the platform to showcase your talent.
            <br />
            <br />
            However, we don't simply end at that point. We acknowledge the strength of working together in our search for knowledge. 
            UploadTheTruth is more than just a storage place for videos; it is an active community where members participate in conversations, 
            exchange different viewpoints, and work together on projects that enhance the shared search for truth. Our online communities, teams, 
            and joint initiatives establish an environment where the sharing of thoughts goes beyond geographical boundaries, cultivating a diverse 
            and vibrant collection of worldwide viewpoints.
            <br />
            <br />
            By becoming a part of UploadTheTruth, you become a member of a group that promotes openness, appreciates the truthfulness of different stories, 
            and empowers people to shape a better and more informed global community. Our dedication extends beyond simply offering a platform; we are nurturing 
            a setting where your opinions are important, your abilities stand out, and your authenticity echoes worldwide.
            <br />
            <br />
            Imagine a place where in-depth analyses, individual anecdotes, and expert exhibitions come together to form a harmonious assembly of reality. 
            Imagine a society where your videos are not only observed but also serve as triggers for transformation. This is UploadTheTruth—a platform 
            where people from all over the world come together to seek the truth while expressing it through the medium of storytelling.
            <br />
            <br />
            Come and join us today to be a part of a life-changing experience. Express your own reality, display your abilities, and participate in a 
            worldwide movement that presents the truth of our world to all of us together. With UploadTheTruth, you have the ability to control and mold the story.
          </p>
        </Col>
      </Row>
    </Container>
  )
}

export default About
