import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Header from '../components/Header';
import About from '../pages/About';
import Footer from '../components/Footer';
import FAQ from '../pages/FAQ';
import ContactUs from '../pages/ContactUs';
import Navbar from '../components/Navbar';
import TermsOfUSe from '../pages/TermsOfUses';
import Privacy from '../pages/Privacy';
import PageNotFound from '../pages/PageNotFound';

// const Login = lazy(() => import('../pages/login/LoginPage'));

class RoutersList extends Component {
    render() {
        return (
            <div style={{paddingTop: 90}} className='min-vh-100 overflow-hidden d-flex flex-column'> {/* overflow-hidden */}
                {/* <Suspense fallback={<div className="loader"></div>}>
                </Suspense> */}
                <BrowserRouter basename="/">
                  <Header/>
                  {/* <Navbar/> */}
                  <Routes>
                    <Route path="/" Component={Home} />
                    <Route path="/about" Component={About} />
                    <Route path="/faq" Component={FAQ} />
                    <Route path="/contact" Component={ContactUs} />
                    <Route path="/terms-of-uses" Component={TermsOfUSe} />
                    <Route path="/privacy" Component={Privacy} />
                    <Route path="/*" Component={PageNotFound} />
                  </Routes>
                  <Footer/>
                </BrowserRouter>

            </div>
        );
    }
}

export default RoutersList; 