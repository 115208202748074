import React from 'react';
import {Row, Col, Button, Container, Image} from 'react-bootstrap';
import LocationBanner from '../assets/images/location_banner.svg';
import Works from '../assets/images/works.svg';
// import MobileScreen from '../assets/images/mobile_screen.svg';
// import TrueFake from '../assets/images/true_fake.svg';
// import Mobile3 from '../assets/images/mobile_3.svg';
// import QrCode from '../assets/images/qr_code.svg';
import MobileScreen from '../assets/images/image-1.png';
import TrueFake from '../assets/images/image-2.png';
import Mobile3 from '../assets/images/image-3.png';
import QrCode from '../assets/images/QR-3.png';

const timingTextStyle = {
  color: "red",
  fontWeight: 'bold',
  fontSize: 18
};

function Home() {
  // React.useEffect(() => {
  //   window.scrollTo({top: 0})
  // },[])
  return (
    <>
    <Container className="py-5">
      <Row className="mt-3 position-relative">
        <Col md={{span: 6, order: 'first'}} xs={{order: 'last'}} className='mt-md-0 mt-5 d-flex justify-content-center align-items-center'>
          <div>
            <p  className='px-2' style={{fontSize: 43, lineHeight: 1.3, fontWeight: 700, fontFamily: 'Inter'}}>
              The one & only medium 
              for <span style={{color: '#FF0026'}}>publishing</span> your <span style={{color: '#00B33E'}}>Truth.</span>
            </p>
            <p  className='px-2'>
              "Upload The Truth" is a mobile app designed for users to share their daily challenges and concerns in concise <span style={timingTextStyle}>2.59s</span> videos or audios and images. The platform covers a wide range of topics such as education, medical issues, politics, national concerns, social problems, representation, police matters, farming, and more.
            </p>
            <Row className='d-flex align-items-center mt-5'>
              <Col lg={5} className='mb-3'>
                <Button variant='success' className='download-btn-2 px-2 py-3 w-100'><i style={{fontSize: 20}} className="fa-brands fa-google-play mx-2"></i>Download for Android</Button>
              </Col>
              <Col lg={5} className='mb-3'>
                <Button variant='success' className='download-btn-2 px-2 py-3 w-100'><i style={{fontSize: 22}} className="fa-brands fa-apple mx-2"></i>Download for ioS</Button>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6} className=" position-relative">
          <div className=" position-relative">
            <Image style={{width: '100%'}} src={MobileScreen}/>
            <div className='round-circle d-flex flex-column justify-content-center align-items-center'>
                <div>
                  <span style={{fontSize: 27, fontWeight: '700', color: 'white'}}>2.59s</span>
                </div>
                <div>
                  <span style={{fontSize: 18, fontWeight: '500', color: 'white'}}>Videos</span>
                </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
    <div style={{zIndex: -1}} className="position-absolute w-100">
      <div className="d-flex flex-column justify-content-center align-items-center w-100 overflow-hidden">
        <Image src={LocationBanner}/>
      </div>
    </div>
    <Container style={{backgroundColor: '#F5F5F5'}} fluid>
      <Container style={{backgroundColor: '#F5F5F5'}}>
        <Row className="mt-3 position-relative justify-content-center align-items-center">
          <Col md={4} className="d-flex justify-content-center align-items-center">
            <Image style={{width: '100%'}} src={TrueFake}/>
          </Col>
          <Col md={6} className='mt-md-0 mt-5 d-flex flex-column justify-content-center px-4 pb-4 pb-md-0'>
            <div>
              <span style={{fontSize: 22, fontWeight: 500, color: '#555555'}} >How to use</span>
              <p style={{fontSize: 34, fontWeight: 700, fontFamily: 'Inter'}}>
                <span style={{color: '#FF0026'}}>Upload The</span><span style={{color: '#00B33E'}}>Truth</span>
              </p>
            </div>
            <div className='d-flex'>
              <div className='position-relative' style={{marginRight: -10, marginTop: 5, border: '1px solid #00A338', borderStyle: 'dashed'}} />
              <div>
                <div>
                  <div className='d-flex align-items-center'>
                    <div style={{backgroundColor: '#00A338', height: 18, width: 18, borderRadius: 9}}/>
                    <div style={{fontSize: 18, fontWeight: 500}} className='mx-3'>Open App</div>
                  </div>
                  <p style={{fontSize: 14, marginLeft: 40}} className=' my-2'>
                  Download the "Upload The Truth" mobile app from the Play Store or App Store and install it on your phone. Open the app and explore its features.
                  </p>
                </div>
                <div>
                  <div className='d-flex align-items-center'>
                    <div style={{backgroundColor: '#00A338', height: 18, width: 18, borderRadius: 9}}/>
                    <div style={{fontSize: 18, fontWeight: 500}} className='mx-3'>Upload Video/Audio/Image</div>
                  </div>
                  <p style={{fontSize: 14, marginLeft: 40}} className=' my-2'>
                      Upload the <span style={timingTextStyle}>2.59s</span> video or audio of whatever you face every day in your life. 
                      It can be societal issues, knowledge-sharing, awareness-raising, a heartfelt message, or an awe-inspiring scene.
                  </p>
                </div>
                <div>
                  <div className='d-flex align-items-center'>
                    <div style={{backgroundColor: '#00A338', height: 18, width: 18, borderRadius: 9}}/>
                    <div style={{fontSize: 18, fontWeight: 500}} className='mx-3'>Share your opinion by vote</div>
                  </div>
                  <div style={{marginTop: -4.5}} className="d-flex position-relative" >
                    <div style={{backgroundColor: '#f5f5f5', width: 10, marginLeft: 7, marginRight: -10}}></div>
                    <p style={{fontSize: 14, marginLeft: 33}} className='my-2'>
                     Express your opinion in a video by using the <span style={{color: '#00A338', fontSize: 18}}>True</span> or <span style={{color: '#FF3351', fontSize: 18}}>Fake
                     </span> Button and share with your friends.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
    <Container className="py-4 mb-5">
      <Row className="mt-3 position-relative justify-content-center">
        <Col md={12} className='d-flex flex-column justify-content-center align-items-center px-4'>
          <h5 style={{fontSize: 34, fontWeight: 700}} className='mb-4'>How  dose it  <span style={{color: '#00A338'}}>Work?</span></h5>
          <div>
            <Image style={{height: 160, width: '100%'}} src={Works}/>
          </div>
          <p style={{textAlign: 'justify'}} className='mt-md-5 mt-2 mt-sm-3'>
          The objective of UploadTheTruth is to present the truth directly in front of people. This platform allows people 
          to showcase their abilities by creating <span style={timingTextStyle}>2.59s</span> short videos, audio, pictures, and articles in addition to sharing the truth. 
          and share your opinion—is it <span style={{color: '#00A338', fontSize: 18}}>True</span> or <span style={{color: '#FF3351', fontSize: 18}}>Fake</span>? 
          We are constructing a worldwide community where individuals can bring forth and distribute the truth throughout the globe.
          </p>
        </Col>
      </Row>
    </Container>
    <Container fluid style={{backgroundColor: '#00A338', marginBottom: '10%', marginTop: '5%' }} >
      <Container >
        <Row className='justify-content-center align-items-center'>
          <Col md={7} style={{maxHeight: 450}} className='px-0 d-flex flex-column justify-content-center align-items-md-end px-md-5'>
              <Image style={{width: '100%', minWidth: 360, maxWidth: 500, marginTop: -50, }} src={Mobile3}/>
          </Col>
          <Col md={5} style={{maxHeight: 450}} className='mt-md-0 pb-md-0 pb-5 mt-5 d-flex flex-column px-md-5 justify-content-center align-items-md-start align-items-center'>
            <div className='py-5 d-flex flex-column justify-content-center align-items-md-start align-items-center'>
              <h5 style={{fontSize: 34, fontWeight: 700, color: 'white'}} >Download Now</h5>
              <p className='mb-4' style={{color: 'white'}}>By using QR Code</p>
              <Image style={{height: 250, width: 250}} src={QrCode}/>
            </div>  
          </Col>
        </Row>
      </Container>
    </Container>
    </>
  );
}

export default Home;
