import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'

function ContactUs() {
  // React.useEffect(() => {
  //   window.scrollTo({top: 0})
  // },[])
  return (
    <Container className='p-4 mt-4'>
      <Row>
        <Col md={6}>
          <h1 className='mb-4'>Contact us</h1>
          <h6>Email : support@uploadthetruth.com</h6>
          {/* <h1 className=''>Get in <span style={{color: '#00B33E'}}>Touch</span></h1>
          <p style={{color: '#717171'}}>if you want to sey something </p>
          <Form>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label style={{color: '#717171'}}>Email address</Form.Label>
              <Form.Control type='email' placeholder='name@example.com' />
            </Form.Group>
            <Form.Group
              className='mb-3'
              controlId='exampleForm.ControlTextarea1'
            >
              <Form.Label style={{color: '#717171'}}>Comment</Form.Label>
              <Form.Control as='textarea' rows={6} />
            </Form.Group>
            <Form.Group
              className='mb-3'
              controlId='exampleForm.ControlTextarea1'
            >
             <Button className='py-2' variant="success" size='md' style={{backgroundColor: '#00B33E', color: 'white', width: '40%', borderRadius: 23}}>SEND</Button>
            </Form.Group>
          </Form> */}
        </Col>
      </Row>
    </Container>
  )
}

export default ContactUs
