import React from 'react'
import { Container } from 'react-bootstrap'

function PageNotFound() {
  // React.useEffect(() => {
  //   window.scrollTo({top: 0})
  // },[])
  return (
    <Container className='p-4 mt-4 d-flex flex-column justify-content-center align-items-center'>
        <h1 className='mb-4'>Sorry, Page not found.</h1>
    </Container>
  )
}

export default PageNotFound
