import 'bootstrap/dist/css/bootstrap.min.css'
import '../src/app/assets/styles/styles.css'
import RoutersList from './app/navigation';

function App() {
  return (
    <div>
      <RoutersList />
    </div>
  );
}

export default App;
