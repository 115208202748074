import React from 'react'
import { Row, Card, Container, Accordion, useAccordionButton, Col  } from 'react-bootstrap'

function CustomToggle({ children, eventKey, setCollapse = () => {} }) {

  // React.useEffect(() => {
  //   window.scrollTo({top: 0})
  // },[])

  const decoratedOnClick = useAccordionButton(eventKey, (res) =>{
    // console.log('totally custom!', res);
    setCollapse();
  });

  return (
    <button
      type="button"
      className='p-3 px-0 w-100'
      style={{ backgroundColor: 'white', border: '0' }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

function FAQ() {
  const [activeKey, setActiveKey] = React.useState(null);

  const setCollapse = (index) => {
    if (activeKey === index) {
      setActiveKey(null);
    } else {
      setActiveKey(index);
    }
  }

  const data = [
    {
      ques_name: "1. What is Upload the Truth?",
      ques_ans: `
      Welcome to the groundbreaking world of UploadTheTruth, where our goal goes beyond the normal and takes you to a realm of honesty, openness, and worldwide bonding. In a modern era filled with an overwhelming amount of information, false information, and various stories, UploadTheTruth shines as a guiding light, committed to uncovering the different aspects of reality and enabling people to express their personal truths through engaging brief clips.
      BRK UploadTheTruth strongly believes that truth is not just something to be bought or sold; it is a powerful influence that shapes how we see things, expands our understanding, and brings people together. We welcome you to be a carrier of truth, a teller of genuineness, and a maker of stories that connect with the common encounters of our worldwide society.
      BRK Our platform is not only a place for sharing information but also a lively space where people from various backgrounds can freely express themselves and display their abilities. If you are a journalist who discovers undisclosed facts, a storyteller who shares impactful stories, or an artist who creates visually impressive narratives, UploadTheTruth gives you the platform to showcase your talent.
      BRK However, we don't simply end at that point. We acknowledge the strength of working together in our search for knowledge. UploadTheTruth is more than just a storage place for videos; it is an active community where members participate in conversations, exchange different viewpoints, and work together on projects that enhance the shared search for truth. Our online communities, teams, and joint initiatives establish an environment where the sharing of thoughts goes beyond geographical boundaries, cultivating a diverse and vibrant collection of worldwide viewpoints.
      BRK By becoming a part of UploadTheTruth, you become a member of a group that promotes openness, appreciates the truthfulness of different stories, and empowers people to shape a better and more informed global community. Our dedication extends beyond simply offering a platform; we are nurturing a setting where your opinions are important, your abilities stand out, and your authenticity echoes worldwide.
      BRK Imagine a place where in-depth analyses, individual anecdotes, and expert exhibitions come together to form a harmonious assembly of reality. Imagine a society where your videos are not only observed but also serve as triggers for transformation. This is UploadTheTruth—a platform where people from all over the world come together to seek the truth while expressing it through the medium of storytelling.
      BRK Come and join us today to be a part of a life-changing experience. Express your own reality, display your abilities, and participate in a worldwide movement that presents the truth of our world to all of us together. With UploadTheTruth, you have the ability to control and mold the story.
      `
    },
    {
      ques_name: "2. How do I create a upload the truth account?",
      ques_ans: `
      First of all, go to the Google Play Store or App Store (Apple users) and download the Upload the Truth mobile app. Then install this app.
      Open this app and follow the left-top-corner login option. Home screen > login option > create an account /sign up by using your email or phone number.
      `
    },
    {
      ques_name: "3. How can I upload a Video? ",
      ques_ans: `
        If you are not logged in, you need to log in first. After entering the homepage, click the [plus icon] Button. Select the video and upload the video.
      `
    },
    {
      ques_name: "4. What is PP (power play)?",
      ques_ans: `
        Power Plays on UploadTheTruth - where you have only 30 seconds to demonstrate your incredible talents. It is a fast-paced environment where each second matters, and you have the opportunity to make a memorable impact instantly. Power Plays are not ordinary videos; they are brief showcases of skill, accuracy, and imagination. Whether you're an athletic superstar or a fast and clever thinker, this is your opportunity to create a significant influence in just 30 seconds. Making a Power Move involves being intelligent and concise. It's about summarizing your knowledge into a 30-second show that impresses viewers. From awe-inspiring sports Power Plays showcase your expertise, captivating a worldwide audience within moments. Become a part of the UploadTheTruth campaign, where there are endless possibilities for your abilities, and the platform is ready for you to excel. No matter if you're an experienced expert or an up-and-coming talent, display your strength within 30 seconds and allow the world to witness the remarkable accomplishments you can achieve. The time is passing, the attention is focused on you - express your honesty with the captivating art of Power Plays.
      `
    },
    {
      ques_name: "5. What type of video can I upload?",
      ques_ans: `
           1.   	Agriculture
       BRK 2.   	Business, Job & Economics
       BRK 3.   	Drugs
       BRK 4.   	Education
       BRK 5.   	Electricity, Gas & Oil
       BRK 6.   	Entertainment, Art & Culture
       BRK 7.   	Food & Drinks
       BRK 8.   	Government & Politics
       BRK 9.   	Health, Medicine & Diagnostic
       BRK 10. 	Introducing (Person, Anything Else)
       BRK 11. 	Immigrant and Expat
       BRK 12. 	Pets & Animals
       BRK 13. 	Police & Armed Force
       BRK 14. 	Religion & Spirituality
       BRK 15. 	Science & Technology
       BRK 16. 	Sports
       BRK 17. 	Tourism & Travel
       BRK 18. 	Transport & Traffic
       BRK 19. 	Theft, Robbery, Rape & Murder
       BRK 20. 	Others
      
      `
    },
  ];
  return (
    <Container className='p-4 mt-4'>
      <h1 className='mb-4'>FAQ</h1>
      <Row>
        <Col md={10}>
          <Accordion activeKey={activeKey}>
            {data.map((item, index) => (
              <div key={index} >
                <CustomToggle as={Card.Header} eventKey={index} setCollapse={() => setCollapse(index)}>
                  <div className="d-flex justify-content-between">
                    <div style={{fontWeight: 'bold', color: activeKey === index ? '#00A338': '#170F49', textAlign: 'left'}}>{item.ques_name}</div>
                    <span style={{fontWeight: 'bold', fontSize: 20, width: 50}}>{activeKey === index ? "-" : "+"}</span>
                  </div>
                </CustomToggle>
                <Accordion.Collapse eventKey={index}>
                  <p style={{color: '#6F6C90', textAlign: 'justify'}} className='p-1 px-0'>
                    {item.ques_ans.split('BRK').map((item) => <p>{item}</p>)}
                  </p>
                </Accordion.Collapse>
              </div>
            ))}
            <div className='mt-5'>
              <h6 style={{fontSize: 18, fontWeight: 'bold', textDecoration: 'underline'}} className='text-danger ' >Note That:</h6>
              <p style={{fontSize: 18, fontWeight: '500'}} className='text-danger '>
                <i className='text-success'>UploadTheTruth</i> is not associated with any political party or public administration. It is a public awareness mobile application.
              </p>
            </div>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
}

export default FAQ;
